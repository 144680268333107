import React from "react"
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet";
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import SuccessCheck from "../components/Icons/SuccessCheck"
import { Success, Description } from "../components/FormStyle"

const Wrapper = styled.div`
	padding: 200px 100px;
	min-height: 100vh;
	background: #EBF8FF;
`

const ThanksPage = ({ data, location }) => (
  <Layout location={location}>
	<Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
    <SEO title={data.contentfulThanksPage.metaTitle} description={data.contentfulThanksPage.metaDescription} />
    <Wrapper>
	    <Description textAlign="center" color="#062C44">
		    <Success><SuccessCheck /></Success>
		    Thank you for connecting with us <br/> Our Representative will get back to you with the requested building quote soon...
	  	</Description>
	  </Wrapper>
  </Layout>
)

export default ThanksPage

export const pageQuery = graphql`
  query ThanksPageQuery($id: String!) {
  	contentfulThanksPage(id: { eq: $id }) {
      metaTitle
      metaDescription
	  }
  }
`